@charset "utf-8";
@import "settings";
@import "assets/vendor/zurb/foundation/scss/foundation";
// @import "assets/components/motion-ui/motion-ui";
@import "flaticon";
@import "slick";
@import "slick-lightbox";
// Or selectively include components
@include foundation-global-styles; //@include foundation-grid;
@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms; // @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar; //@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes; //@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
// @include motion-ui-transitions;
// @include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
// FONTS
//////////////////////////////////////////////////////////////////////////
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
@mixin stak {
  // defaults;
  padding: 20px 0;
  position: relative;
  z-index: 1;
  background-color: $white;
  p {
    font-size: 0.8em;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  h1, h2, h3 {
    text-transform: uppercase;
    margin-top: 0.5em;
    @include breakpoint(medium) {
      margin-top: 0em;
    }
  }
  h1 {
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: $header-margin-bottom * 3;
    display: block;
  }
  h2 {
    font-weight: 900;
    line-height: 1em;
    margin-bottom: $header-margin-bottom * 3;
    display: block;
  }
  h3 {
    font-weight: 900;
    margin-bottom: $header-margin-bottom * 3;
    display: block;
  }
  &:after {
    background: $white;
  }
  @include breakpoint(medium) {
    // enhancements;
    padding: 30px 0;
  }
  @include breakpoint(large) {
    // enhancements;
    padding: 40px 0;
    //max-width: 75rem;
    margin: 0 auto;
    p {
      font-size: 1em;
      &.lead {
        font-size: 125%;
        line-height: 1.6;
      }
    }
  }
  &.collapse {
    padding: 0 !important;
  }
}
@mixin hero-slide {
  // defaults;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  backface-visibility: hidden;
  align-items: center;
  display: flex;
  @include breakpoint(medium) {
    // enhancements;
    height: 350px;
  }
  @include breakpoint(large) {
    // enhancements;
    height: 450px;
  }
  @include breakpoint(xlarge) {
    // enhancements;
    height: 500px;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  @include stak;
  display: table;
  width: 100%;
  min-height: 300px;
  .row {
    display: table-row;
    width: 100%;
  }
  .columns {
    display: table-cell;
    float: none;
    vertical-align: middle;
    text-align: center;
  }
  .column:last-child:not(:first-child),
  .columns:last-child:not(:first-child) {
    float: none;
  }
  .vertical-center {
    vertical-align: middle;
  }
  h2 {
    //color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  h3,
  li,
  p,
  strong {
    //color: $white;
  }
  @include breakpoint(large) {
    background-attachment: fixed;
  }
}
@mixin colored-stak {
  position: relative;
  //padding-left: 2em;
  //padding-right: 2em;
  &:after {
    content: "";
    position: absolute;
    mix-blend-mode: normal;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
  &.photo-bgd-callout {
    .photo-bgd {
      &:after {
      }
    }
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 3px;
}
@mixin flaticon {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
@mixin overlay-blue {
  content: "";
  background: rgba($primary-color, .5);
  background: linear-gradient(135deg, rgba($green, 1) 0%, rgba($green, 0) 50%);
  position: absolute;
  mix-blend-mode: darken;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
}
@mixin overlay-gradient {
  @include overlay-blue;
  opacity: .75;
  background: linear-gradient(135deg, $green 0%, darken($green, 10%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  mix-blend-mode: normal;
}
.gallery {
  figure.gallery-item {
    float: left;
    margin: 0 10px;
  }
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
$logo-small: 160px;
$logo-medium: 140px;
$logo-large: 240px;
.main-section, .product-page {
  position: relative;
  //background-image: url("/wp-content/uploads/2017/03/background-sample.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
}
address {
  font-style: normal;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
  > .columns {
    padding: 0;
  }
}
:focus {
  outline: none;
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
.slick-arrow {
  z-index: 1;
}
.go-button {
  @include button;
  @include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  overflow: hidden;
  width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  &:before {
    content: '';
    background: darken($primary-color, 10%) url("../images/site/arrowhead.png") center center no-repeat;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
.button:not(.icon-search) {
  animation: none;
  font-weight: 600;
  &:hover {
    box-shadow: 0px 20px 20px -5px rgba($black, 0.4);
    //  animation: pulse 2s infinite;
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:after {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:after {
    color: $orange;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $orange;
    }
  }
}
ul.row {
  list-style: none;
  li {
    margin: 0.5em 0;
    line-height: 1.25em;
  }
}
.stak-pic {
  @include stak-pic;
}
// prevent mobile no-scroll
.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
.skip-link.screen-reader-text {
  display: none;
}
.sticky-container {
  transition: all 0.25s;
  z-index: 999; // for dropdown pre-scroll
}
header.main-nav {
  background-color: rgba($white, 0.95);
  transition: all 0.25s;
  position: relative;
  .logo {
    width: $logo-small;
    margin: 0.5em 0;
    transition: all 0.25s;
  }
  @include breakpoint(medium) {
    .logo {
      width: $logo-medium;
    }
  }
  @include breakpoint(large) {
    // enhancements;
    .logo {
      width: $logo-large;
    }
  }
  @include breakpoint(xlarge) {
    // enhancements;
    // width: 100%;
    // position: fixed;
    // z-index: 2;
    // top: 0;
  }
  // GLOBAL-MENU ///////////////////////////////////////////////
  .global-menu-container {
    background-color: $light-gray;
    max-width: 100%;
  }
  ul.global-menu {
    display: flex;
    vertical-align: baseline;
    width: 100%;
    list-style-type: none;
    text-transform: uppercase;
    flex-wrap: wrap;
    justify-content: center;
    //flex: 1 1 0px;
    > li {
      position: relative;
      text-align: left;
      vertical-align: bottom;
      border-right: $light-green solid 1px;
      display: flex;
    }
    > li > a {
      font-family: $header-font-family;
      font-size: 0.78em;
      padding: .5em 1.25em;
      color: $gray;
      line-height: 1.25em;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.5s;
      position: relative;
      flex-direction: column;
      align-self: flex-end;
      text-align: center;
      &:hover {
        color: $secondary-color;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $primary-color;
      }
      &.sel {
        color: $secondary-color;
        &:after {
          width: 100%;
          background: $secondary-color;
        }
      }
    }
    > li:last-child {
      border-right: none;
    }
    @include breakpoint(large) {
      flex-wrap: nowrap;
      > li > a {
        // font-size: .85em;
        padding: .5em .9em;
      }
    }
    @include breakpoint(xlarge) {
      > li > a {
        font-size: .85em;
        font-weight: bold;
        padding: 1em 1em;
      }
    }
  }
  .dropdown-pane {
    font-size: 0.9em;
    text-align: left;
    &.mega-menu {
      width: 100% !important;
      border: none;
      background-color: transparent;
      padding: 0;
      &.is-open {
        left: 0 !important;
        display: block;
      }
      .content {
        background-color: $white;
        padding: 2em 1.25em 1.25em 1.25em;
        border: 1px solid $medium-gray;
        a {
          display: inline-block;
          line-height: 1.25em;
          &:hover {
            color: $secondary-color;
          }
        }
        .description {
          border-top: 1px solid $light-gray;
          padding-top: 0.5em;
          color: $gray;
          p {
            line-height: 1.25em;
          }
        }
        .nav-title {
          font-size: 14px;
          font-family: $header-font-family;
          font-weight: 600;
          text-transform: uppercase;
          //letter-spacing: 0.05em;
          color: $gray;
          display: block;
          margin-bottom: 1em;
        }
        .menu .button,
        .menu a {
          line-height: 1.25;
          text-decoration: none;
          display: block;
          padding: 0 .75rem 0 0;
        }
        .blocks {
          li {
            display: inline-block !important;
          }
          a {
            font-weight: normal;
            display: inline-block !important;
          }
          .menu-ico {
            max-width: 30px;
            display: inline;
            padding-right: 6px;
            line-height: 1em;
            margin: 0;
          }
        }
      }
      img {
        width: 100%;
        margin: 0.75em 0;
        display: block;
      }
    }
    .menu {
      margin-top: -1.25em;
      margin-bottom: 1em;
      a {
        padding: 0.7rem 1rem 0 1rem;
        &:hover {
          text-decoration: underline;
          color: scale-color($anchor-color, $lightness: -40%);
        }
      }
    }
    .menu.vertical {
      margin-top: .5em;
    }
  }
}
.is-stuck {
  header.main-nav {
    background-color: rgba($white, 0.95);
    height: auto;
    @include breakpoint(xlarge) {
      // enhancements;
      .logo {
        width: $logo-medium;
      }
    }
  }
}
.xlarge-mega {
  flex-wrap: wrap;
  > .columns {
    flex: 0 0 25%;
    margin-bottom: 1em;
    @include breakpoint(xlarge) {
      flex: 0 0 14.28571%;
      max-width: 14.28571%;
    }
  }
}
// SEARCH
.is-stuck {
  .header-search {
    .input-group {
      margin-bottom: 0rem;
      @include breakpoint(large) {
        margin-top: .15em;
      }
    }
  }
}
.header-search {
  .input-group {
    margin-bottom: 0rem;
  }
  //  Ancilary Search
  .input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label, [type='text'] {
    height: 2rem;
  }
  // Search
  .input-group-field {
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom: 1px solid $secondary-color;
    //border-bottom-left-radius: 20px;
    color: $gray;
    background-color: $light-gray;
    margin: 0;
    padding-left: 1em;
    //box-shadow: inset 0 1px 2px rgba($gray, .5);
    &::placeholder {
      color: $medium-gray;
      font-size: .9em;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
  .button.icon-search {
    background: $secondary-color url('../images/icon-search.svg') center no-repeat;
    background-size: 1em;
    width: 2em;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &:hover {
      background-color: $button-background-hover;
    }
    @include breakpoint(large) {
      background-size: 1.5em;
      width: 3.5em;
    }
  }
}
// Ancillary Menu
.ancillary {
  text-align: right;
  display: block;
  background-color: lighten($light-gray, 2%);;
  border-bottom: 1px solid $light-gray;
  ul.ancillary-menu {
    margin: 0 !important;
    font-size: 0.8em;
    padding: 0.5em 0;
    display: inline-flex;
    list-style-type: none;
    align-items: center;
    flex: 1 1 0px;
    li {
      list-style-type: none;
      padding: 0 0 0 10px;
      a {
        color: $dark-gray;
        padding: 0 0.5rem;
        &:hover {
          color: $primary-color;
        }
      }
      form {
        display: inline;
      }
    }
    .phone {
      font-weight: bold;
    }
    .contact {
      @include button;
      @include button-hollow;
      font-size: 1em;
      border: lighten($secondary-color, 20%) 1px solid;
      color: $secondary-color;
      margin-bottom: 0;
      transition: all 0.25s;
      margin: 0;
      a {
        color: $secondary-color;
      }
      &:hover {
        border: $secondary-color 1px solid;
      }
    }
  }
  a:hover:before {
    color: $secondary-color;
  }
  .account-link {
    &:before {
      @include flaticon;
      content: "\f11a";
      margin-right: .25em;
      font-size: 1.25em;
      color: $primary-color;
    }
  }
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
  text-align: center;
  padding: 0.5em 0 0.5em;
  line-height: 1em;
  position: relative;
  .logo {
    width: $logo-small;
    margin: 0 auto;
    padding: 0;
    .fill-black {
      fill: $graphite;
    }
  }
  .menu-ico {
    //margin: -2px 0.3em;
    border: none;
    background: none;
    z-index: 99999;
    transform: scale3d(0.8, 0.8, 0.8);
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 10px;
    top: 1em;
    span {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: 0.3s;
      position: relative;
    }
    span:before {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: 0.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
    }
    span:after {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: -.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
    &:hover {
      span {
        background: lighten($secondary-color, 10%);
      }
      span:before {
        background: lighten($secondary-color, 10%);
      }
      span:after {
        background: lighten($secondary-color, 10%);
        //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      }
    }
  }
}
.menu-ico[aria-expanded="true"] {
  //background-color: $primary-color;
  span {
    background: transparent !important;
  }
  span:before {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
  span:after {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
}
.right-off-canvas-menu {
  padding-top: 1rem;
  //background-color: rgba($jet, .9);
  .menu {
    margin: 0 0 1em 0;
  }
  .menu li a {
    display: block;
    border-bottom-color: darken($green, 10%);
    border-top: 1px solid rgba($white, 0.15);
    color: $white;
    &:hover {
      color: lighten($secondary-color, 50%);
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  background-color: $green;
  color: $white;
  //padding: 2em 0 0;
  font-size: 0.9em;
  //*:not(.button){color: $gray;}
  text-align: center;
  h5 {
    color: $white;
  }
  h6 {
    padding: .5em 0 0 .5em;
  }
  a {
    color: $white;
  }
  a:hover {
    text-decoration: underline;
    color: lighten($primary-color, 50%);
  }
  .company-info {
    margin: 0 auto 2em 0;
  }
  .mini-map {
    background-color: darken($primary-color, 2%);
    padding-bottom: 1em;
  }
  .menu {
    .nav-title {
      @include header-size(h5);
      font-weight: bold;
      font-family: $header-font-family;
      margin-top: 1em;
      color: $white;
    }
    a {
      padding: 0.45rem 0rem;
    }
  }
  .logo {
    vertical-align: middle;
    margin: 0 auto;
    max-width: $logo-small;
  }
  ul.display-posts-listing {
    @include no-bullet;
    li {
      border-bottom: 1px solid rgba($white, .2);
      padding: 0.5em 0;
      &:first-of-type {
        padding: 0 0 0.5em;
      }
    }
    a {
      text-decoration: none;
    }
  }
  .contact-info {
    text-align: center;
    margin: 2em auto 1em auto;
    color: $medium-gray;
    flex: 0 0 100%;;
    h5 {
      display: none;
    }
    p {
      font-size: 0.9em;
      //color: $white;
      vertical-align: middle;
    }
    address {
      margin: 1.5em 0 0 0;
      span {
        display: block;
      }
      strong {
        color: $light-green;
        width: 2em;
        display: inline-block;
      }
    }
    .telephone {
      margin: 0.5em 0;
    }
  }
  .copyright-container {
    background-color: darken($primary-color, 4%);
    padding: 1em 0;
    p {
      display: inline-block;
      margin: auto;
    }
  }
  .copyright {
    display: block;
    a {
      color: lighten($primary-color, 50%);
    }
  }
  .disclosure {
    text-align: center;
    @include breakpoint(medium) {
      text-align: right;
    }
  }
  .social-icons {
    font-size: 1.25em;
    a {
      margin-right: 0.5em;
    }
    //[class^="flaticon-"]:hover:after,
    //[class*="flaticon-"]:hover:before {
    //}
  }
  .telephone {
    margin: 0.5em 0;
  }
  .jobs-btn {
    @include button($expand: true, $background: $white, $style: hollow);
    transition: all, 0.25s;
    border-width: 2px;
    &:hover {
      color: lighten($primary-color, 50%);
      border-color: lighten($primary-color, 50%);
    }
  }
  @include breakpoint(medium) {
    text-align: left;
    .contact-info {
      text-align: left;
      flex: 0 0 auto;
    }
    .copyright, .links {
      display: inline-block;
      margin-right: .5em;
    }
    h5 {
      margin-top: 0;
      font-weight: bold;
    }
    .logo {
      margin: 0;
    }
  }
  .footer-signup {
    padding-top: 1em;
    p {
      width: 100%;
      @include breakpoint(medium) {
        //width: 50%;
        float: right;
        padding-left: 1em;
      }
    }
  ;
  }
  //MAILCHIMP
  #mc_embed_signup {
    background: transparent;
    width: 100%;
    form {
      padding: 0;
      text-align: center;
    }
    input.email {
      display: inline;
      width: 60%;
      margin: 0 0 10px 0;
      height: 40px;
      border-color: rgba($white, .9);
      border-width: 1px;
      border-style: solid;
      background-color: rgba($white, 0);
      transition: all .25s;
      border-radius: 0;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      &::placeholder {
        color: $white !important;
      }
      &:focus, &:active, &:target {
        background-color: rgba($white, 1);
      }
    }
    input.button {
      display: inline;
      width: 18%;
      background-color: $button-background;
      height: 40px;
      border-radius: 0;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      &:hover {
        background-color: $button-background-hover;
      }
    }
    @include breakpoint(medium) {
      width: 50%;
      float: left;
      input.email {
        display: inline;
        width: 70%;
      }
    }
    @include breakpoint(large) {
      input.email {
        width: 78%;
      }
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.5em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
// SUBNAV
.subnav {
  text-align: center;
  background-color: $light-gray;
  align-items: center;
  align-content: center;
  .vertical {
    text-align: left;
    &:first-of-type {
      margin-bottom: 2em;
    }
    a {
      display: inline-block
    }
  }
  ul.menu {
    vertical-align: baseline;
    width: 100%;
    list-style-type: none;
    text-transform: uppercase;
    align-self: center;
    text-align: center;
    flex: 1 1 0px;
    > li {
      position: relative;
      text-align: left;
    }
    > li > a {
      font-family: $header-font-family;
      font-size: 0.78em;
      padding: 0 1.25em;
      color: $gray;
      line-height: 3em;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.5s;
      position: relative;
      text-align: left;
      &:last-child {
        padding-right: 0;
      }
      &:hover {
        color: $secondary-color;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $primary-color;
      }
      &.sel {
        color: $secondary-color;
        &:after {
          width: 100%;
          background: $secondary-color;
        }
      }
    }
  }
}
.stak {
  @include stak;
  //  background-color: $white;
}
.alt-stak {
  @include stak;
  background: $light-gray;
  background: linear-gradient(135deg, rgba($light-gray, .5) 0%, $white, 80%, rgba(darken($light-gray, 20%), 1) 100%);
}
.gray-stak {
  @include stak;
  background: $light-gray;
}
.green-stak {
  @include colored-stak;
  &:not(.photo-bgd-callout) {
    color: $white;
    h2, h3, h4, p, ul {
      color: $white;
    }
  }
  a {
    color: $light-green
  }
  .card-section {
    h4, p {
      color: $body-font-color;
    }
  }
  &:after {
    background: $primary-color;
  }
  &.photo-bgd-callout {
    h3 {
      color: $white;
    }
    .photo-bgd {
      &:after {
        color: $secondary-color;
      }
    }
  }
  .button {
    border: 1px solid $white !important;
  }
  .woocommerce li.product .price {
    color: lighten($light-green, 10%) !important;
    font-weight: bold;
  }
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
#home-top-bar {
  width: 100%;
}
.home-hero {
  margin: 0;
  backface-visibility: hidden;
  position: relative;
  .hero-caption {
    padding: 0 40px;
    //position: absolute;
    z-index: 2;
    //top: 0;
    //right: 0;
    //left: 0;
    //width: 90%;
    text-align: center;
    //padding: 100px 0 0;
    h1 {
      font-weight: bold;
      margin: 0;
    }
    h2 {
      font-family: $body-font-family;
      font-weight: 300;
      margin: 0;
    }
    h1,
    h3 {
      color: $white;
      line-height: 1em;
    }
    h1 {
      font-size: f-header-size(small, h1);
    }
    h2 {
      color: $white;
      line-height: 1.15em;
      color: lighten($secondary-color, 40%);
      font-size: f-header-size(small, h2) - .5;
    }
    p {
      color: $white;
      font-size: $paragraph-font-size - .25;
      margin-top: 1em;
      a {
        color: $primary-color;
      }
    }
    .button {
      @include button;
      margin-top: 1em;
    }
  }
  @include breakpoint(medium) {
    .hero-caption {
      text-align: left;
      p {
        font-size: $paragraph-font-size;
      }
      .button {
        @include button;
        margin-top: 2em;
      }
      h2 {
        font-size: f-header-size(small, h2) - .25;
      }
    }
  }
  @include breakpoint(large) {
    .hero-caption {
      .columns {
        width: 75rem;
        margin: 0;
      }
      padding: 100px 40px 0 40px;
      h1 {
        font-size: f-header-size(medium, h1);
      }
      h2 {
        font-size: f-header-size(small, h2);
      }
    }
  }
  @include breakpoint(xlarge) {
    .hero-caption {
      padding: 100px 0 0 0;
      h1 {
        font-size: f-header-size(medium, h1);
      }
    }
  }
}
.carousel-hero {
  margin-top: 0;
  margin-left: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  .slick-prev {
    left: 20px;
  }
  .slick-prev, .slick-next {
    &:before {
      font-size: 20px;
    }
  }
  @include hero-slide;
  // @include breakpoint(large) {
  //    .slick-next,
  //    .slick-prev {
  //       top: 48%;
  //    }
  // }
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include hero-slide;
    position: relative;
    // @include breakpoint(xlarge) {
    //    clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
    // }
    &:before {
      @include overlay-gradient;
      z-index: 2;
    }
  }
  .slide-caption {
    @include grid-column(12);
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    font-size: $paragraph-font-size - 0.25;
    text-align: center;
    p {
      background-color: rgba($white, 0.9);
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      padding: 1em;
      display: inline;
      border: 1px solid rgba($white, 0.8);
    }
  }
  @include breakpoint(medium) {
    .slide-caption {
      font-size: $paragraph-font-size;
    }
  }
}
.hover-callouts {
  margin: 0 auto;
  > div {
    vertical-align: bottom;
    transition: all 0.5s;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    overflow: hidden;
    background-clip: content-box !important;
    min-height: 300px;
    display: table;
    border: 4px solid $white;
    &:first-of-type {
      border-bottom: 4px solid $white;
    }
    > div {
      border-collapse: collapse;
      display: table-row;
      background-color: rgba($dark-gray, .9);
    }
    .meta {
      height: 96%;
      text-align: center;
      border: 1px solid $transparent;
      padding: 0.5em;
      margin: 0.5em 0 0;
      display: table-cell;
      vertical-align: middle;
      h3,
      h4 {
        font-weight: 400;
        color: $white;
        margin-bottom: 0;
        line-height: 1.25em;
      }
      hr {
        margin: 0.5em 0;
        border-color: teal;
      }
      p {
        color: $white;
        margin: 1em 0;
      }
    }
    @include breakpoint(medium) {
      overflow: hidden;
      background-size: cover !important;
      transition: all 0.25s;
      min-height: 400px;
      display: block;
      > div {
        background-color: rgba($dark-gray, 0.65);
        transition: all 0.5s;
        height: 100%;
        padding: 0.5em;
        display: block;
      }
      .meta {
        transform: translate(0px, 40%);
        transition: all 0.5s;
        display: block;
        h3 {
          color: $white;
        }
        p {
          color: $white;
          opacity: 0;
          transition-delay: 0;
        }
        hr {
          opacity: 0;
          transition-delay: 0;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
      }
      &:hover {
        > div {
          background-color: rgba($dark-gray, .9);
        }
        .meta {
          transform: translate(0px, 30%);
          transition-delay: 0.25s;
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          hr {
            opacity: 1;
            transition-delay: 0.25s;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.5s;
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
  }
}
.stats {
  margin-top: 2em;
  font-family: $header-font-family;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  .column {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  strong {
    font-weight: 600;
    display: block;
    font-size: 1.5em;
    line-height: 1.5em;
    color: $primary-color;
  }
  @include breakpoint(large) {
    font-size: 1em;
  }
}
.testimonials {
  text-align: center;
  .row {
    align-items: center;
    justify-content: center;
  }
}
.quote-slider {
  blockquote {
    margin: 30px 0 0;
    border-left: none;
    p {
      margin: 0;
      letter-spacing: 0.1em;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
    }
    footer {
      margin: 10px 0;
      font-style: italic;
      font-family: $font-family-serif;
    }
    @include breakpoint(medium) {
      p {
        font-size: 120%;
      }
    }
  }
}
blockquote.quotation {
  margin: 30px 0 0;
  border-left: none;
  p {
    margin: 0;
    letter-spacing: 0.1em;
    &:before {
      content: '“';
    }
    &:after {
      content: '”';
    }
  }
  footer {
    margin: 10px 0;
    font-style: italic;
    font-family: $font-family-serif;
  }
  @include breakpoint(medium) {
    p {
      font-size: 120%;
    }
  }
}
.category-logos {
  margin: 20px 0 0;
  .ri-grid {
    margin: 0 auto;
    position: relative;
    height: auto;
  }
  .ri-grid ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  /* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
  .ri-grid ul:after,
  .ri-grid ul:before {
    content: '';
    display: table;
  }
  .ri-grid ul:after {
    clear: both;
  }
  .ri-grid ul {
    zoom: 1;
    /* For IE 6/7 (trigger hasLayout) */
  }
  .ri-grid ul li {
    perspective: 250px;
    //height: 200px!important;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    display: block;
    overflow: hidden;
    //border: 1em solid $white;
    @include breakpoint(medium) {
      //border: 1em solid transparent;
    }
  }
  .ri-grid ul li a,
  .ri-grid ul li div {
    display: block;
    outline: none;
    position: absolute;
    z-index: 1002;
    margin: 0 auto;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: content-box;
    filter: grayscale(100%) contrast(110%);
    transition: all 0.25s ease-in-out;
    &:hover {
      filter: grayscale(0%) contrast(100%);
    }
  }
  /* Grid wrapper sizes */
  .ri-grid-size-1 {
    width: 55%;
  }
  .ri-grid-size-2 {
    width: 100%;
  }
  .ri-grid-size-3 {
    width: 100%;
    //margin-top: 0;
  }
  .ri-grid-loading:after,
  .ri-grid-loading:before {
    display: none;
  }
  .ri-loading-image {
    display: none;
  }
  .ri-grid-loading .ri-loading-image {
    position: relative;
    width: 30px;
    height: 30px;
    left: 50%;
    margin: 100px 0 0 -15px;
    display: block;
  }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.landing {
  h2 {
    //display: initial;
  }
  .hero {
    min-height: 300px;
    background-size: 100%;
    background-position: top center;
    //margin-bottom: 2.500em;
    position: relative;
    //background: $green; /* Old browsers */
    //background: linear-gradient(135deg, $green 0%, $gold 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$green', endColorstr='$gold', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    &:before {
      @include overlay-gradient;
      z-index: 1;
    }
    .hero-caption {
      color: white;
      text-align: center;
      transform: translate(0%, -50%);
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      background-position: center center;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        text-transform: uppercase;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 350px;
      background-size: cover !important;
    }
    @include breakpoint(large) {
      min-height: 350px;
      background-size: cover !important;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) - 0.5;
        }
        h2 {
          font-size: f-header-size(medium, h2) - .5;
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 350px;
      .hero-caption {
        padding-top: 4em;
        // h1 {
        //    font-size: f-header-size(medium, h1) + 1.25;
        // }
        // h2 {
        //    font-size: f-header-size(medium, h2) + 1;
        // }
      }
    }
  }
  //.stak:nth-child(even) {
  //  background-color: $light-gray;
  //}
}
// WooCommerce Category and Archive page styles
//////////////////////////////////////////////////////////////////////////
.page-products {
  .woocommerce-breadcrumb {
    display: none;
  }
}
//.product-menu {
//  margin-bottom: 2em;
//  background-color: $light-gray;
//  font-size: .75em;
//  padding: 1em;
//  .nav-title {
//    font-weight: bold;
//    text-transform: uppercase;
//    color: $body-font-color;
//    &:hover{
//      text-decoration: underline;
//    }
//  }
//  .menu a {
//    line-height: 1.25;
//    text-decoration: none;
//    display: block;
//    padding: .75rem 0 0 0;
//    color: $body-font-color;
//    &:hover{
//      text-decoration: underline;
//    }
//    .menu-ico {
//      max-width: 30px;
//      display: inline;
//      padding-right: 6px;
//      line-height: 1em;
//      margin: 0;
//    }
//  }
//}
.category-landing {
  .content-loop {
    margin: 30px 0;
    h3 {
      font-size: 1rem;
      margin-bottom: 0;
    }
    tbody th, tbody td {
      padding: 0.25rem 0.9rem 0.25rem;
      font-size: 0.9em;
    }
    a.button {
      background-color: $green !important;
      color: $white !important;
    }
  }
  h2 {
    display: initial;
  }
  .hero {
    min-height: 200px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    align-content: center;
    display: flex;
    //background-image: url("/wp-content/uploads/2017/03/background-sample.jpg");
    //background-color: $green; /* Old browsers */
    //background: -moz-linear-gradient(-45deg, $green 0%, $gold 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(-45deg, $green 0%, $gold 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(135deg, $green 0%, $gold 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$green', endColorstr='$gold', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    &:before {
      @include overlay-gradient;
    }
    .hero-caption {
      text-align: center;
      flex: 1 1 0px;
      margin: auto;
      z-index: 1;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        text-transform: uppercase;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      background-size: cover !important;
      background-position: center bottom;
    }
    @include breakpoint(large) {
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) - 0.5;
        }
        h2 {
          font-size: f-header-size(medium, h2) - .5;
        }
      }
    }
  }
  //.stak:nth-child(even) {
  //  background-color: $light-gray;
  //}
}
//End Category and Archive page styles
.photo-bgd-callout {
  background-position: center center !important;
  background-size: cover;
  position: relative;
  &:before {
    @include overlay-gradient;
    z-index: 0;
  }
  .content {
    margin: 2em auto;
    padding: 1em;
    background-color: rgba($white, .9);
    text-align: center;
  }
  @include breakpoint(medium) {
    background-attachment: fixed !important;
    .content {
      margin: 4em auto;
      padding: 1em 2em;
      background-color: rgba($white, .9);
    }
  }
}
.cards-stak {
  .card {
    border: none;
    border-radius: 10px;
    box-shadow: 0px 20px 20px -5px rgba($black, 0.4);
    min-height: 325px;
    margin-bottom: 2em;
    text-align: center;
    //h4 {
    //  min-height: 46px;
    //}
  }
  .card-description {
    margin-bottom: 1em;
  }
  .button {
    margin: 1em auto;
    text-align: center;
  }
  .card-link {
    text-align: center;
  }
  figure {
    position: relative;
    &:before {
      @include overlay-gradient;
      z-index: 1;
      opacity: .25;
      mix-blend-mode: multiply;
    }
  }
}
// VIDEO
.flex-video {
  iframe {
    border: 0;
  }
}
.video-backbground {
  background-color: $light-gray;
  padding: 15px;
  margin: 0 15px !important;
}
.video-player {
  // background-color: #fff;
  padding-bottom: 5px;
  .poster {
    position: relative;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
h3,
h4 {
  &.underline {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: $gray;
    }
  }
  line-height: 1.15em;
}
.align-middle,
.collapse,
.fill-image {
  .content {
    padding: 2em;
  }
  @include breakpoint(xlarge) {
    .content {
      padding: 4em;
    }
  }
}
.fill-image {
  .columns {
    min-height: 200px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    @include breakpoint(medium) {
      background-size: auto 100%;
    }
  }
}
.alternating-staks {
  .stak {
    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }
  img.thumbnail {
    margin: 0.5em auto;
    width: 90%;
  }
}
.gallery-photo {
  &.slick-slider {
    margin-bottom: 4px !important;
  }
  > div {
    // max-width: 320px;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 0;
    img {
      //max-width: 640px;
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      transform: scale(1);
      transition: transform 0.25s;
    }
  }
}
.gallery-thumb {
  //max-width: 640px;
  max-height: 98px;
  background-color: $white;
  div.slick-slide {
    //min-width: 120px;
    max-height: 90px !important;
    overflow: hidden;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    margin: 0 2px;
    .slick-list {
      max-height: 90px;
    }
    img {
      max-width: 120px;
      min-height: 90px;
      vertical-align: middle;
      padding: 0 4px 0 0;
      margin: 0 auto;
    }
    @include breakpoint(large) {
      img {
        max-width: 150px;
        min-height: 120px;
      }
    }
  }
  .slick-next,
  .slick-prev {
    top: 0;
    background-color: rgba($white, .9);
    z-index: 1;
    height: 100%;
    &:before {
      color: $black;
    }
  }
}
.photo-gallery {
  justify-content: center;
  text-align: center;
}
// BLOG
/////////////////////////////////
// Article
.more-articles {
  padding-top: 2em;
  .button {
    border-radius: $button-radius;
  }
}
.article-cards, .tease-post {
  .card {
    transition: all 0.5s;
    display: block;
    background-color: $light-gray;
    overflow: hidden;
    //margin: .9em;
    p {
      font-family: $font-family-serif;
      font-size: .8em;
      margin: 1.5em auto 1.75em;
    }
    .date {
      color: $gray;
      font-family: $font-family-serif;
      font-size: .8em;
    }
    .article-thumb {
      width: 100%;
      display: block;
      position: relative;
    }
    .article-categories {
      margin-bottom: 1em;
      a {
        text-transform: uppercase;
        font-size: .8em;
        color: $secondary-color;
        font-weight: bold;
        &:hover {
          color: lighten($primary-color, 8%);
        }
        &:before {
          content: ", ";
        }
      }
      a:first-of-type {
        &:before {
          content: "";
        }
      }
    }
    &:hover {
      img {
        width: 110%;
      }
    }
  }
  @include breakpoint(medium) {
    .card {
      transition: all 0.25s;
      display: block;
      margin: .9em;
      .card-section {
        transition: all 0.5s;
        padding: 1.5em 1em;
      }
    }
    padding-bottom: 2em;
  }
}
.breadcrumbs {
  margin: 1em 0;
}
.pagination-block {
  text-align: center;
  border-top: 2px solid $light-gray;
  padding-top: 2em;
  padding-bottom: 2em;
  font-family: inherit;
  .disabled {
    color: lighten($gray, 20%);
  }
  .prev {
    float: left;
  }
  .next {
    float: right;
  }
}
.article {
  h2, h3, h4 {
    color: $gray;
    font-weight: normal;
    font-family: $font-family-serif;
    b {
      font-weight: normal;
    }
  }
  p {
    a {
      color: lighten($primary-color, 20%);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  .cat-title-link {
    a {
      padding: 0 .25em;
      &:after {
        content: ","
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  .featured-image {
    margin-bottom: 2em;
  }
  .article-pagenation {
    margin-top: -1em;
    margin-bottom: 1em;
    a {
      h4 {
        color: $gray;
        //text-decoration: underline;
        font-size: .9em;
        margin-bottom: 0;
      }
      span {
        font-size: .8em;
        line-height: 1em;
        display: block;
        color: lighten($primary-color, 20%);
      }
      &:hover {
        h4, span {
          color: $secondary-color !important;
        }
      }
    }
    @include breakpoint(medium) {
      justify-content: space-between;
      margin-top: -3em;
      margin-bottom: 2em;
      .next-post {
        text-align: right;
      }
    }
  }
}
.recent-articles-list {
  margin-bottom: 1em;
  img {
    margin-right: 1em;
  }
  .article-date {
    font-size: .8em;
    margin-bottom: 0;
  }
  .article-title {
    h6 {
      margin-top: 0;
    }
    a:hover h6 {
      //   color: lighten($primary-color, 8%);
    }
  }
}
.category-list {
  a {
    padding: 0.7rem 1rem .7rem;
    display: inline;
    font-size: .8em;
  }
  .article-count {
    display: inline-block;
    width: 1.5em;
    color: $gray;
    margin-right: .25rem;
    font-size: .8em;
  }
}
// .comment-box {
//    margin-top: 2em;
//    padding-top: 2em;
//    border-top: 4px solid $light-gray;
// }
// .comment-form {
//    input, textarea {
//       border: none;
//       background-color: $light-gray;
//       box-shadow: none;
//       color: $gray;
//       &::placeholder {
//          color: lighten($gray, 20%);
//       }
//       &:focus::placeholder{
//          color:transparent;
//       }
//    }
//    input {
//       margin-bottom: 1em;
//    }
//    label {
//       display: none;
//    }
//    textarea {
//       min-height: 148px;
//    }
//    sup {
//       display: block;
//       font-family: $font-family-serif;
//       margin: 1em auto;
//       font-style: italic;
//       color: $gray;
//    }
//    h4 {
//       display: block;
//       font-style: normal;
//       text-transform: uppercase;
//       font-size: f-header-size(small, h6)*.8;
//       color: $primary-color;
//       font-weight: bold;
//       margin-bottom: 1em;
//    }
// }
// SUBPAGE
.subpage {
  h2 {
    display: initial;
  }
  .hero {
    min-height: 300px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    //background: $green; /* Old browsers */
    //background: linear-gradient(135deg, $green 0%, $gold 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$green', endColorstr='$gold', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    &:before {
      @include overlay-gradient;
      z-index: 1;
      background-image: url("/wp-content/uploads/2017/03/background-sample.jpg");
    }
    .hero-caption {
      color: white;
      text-align: center;
      transform: translate(0%, -50%);
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        text-transform: uppercase;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 350px;
      background-size: cover !important;
    }
    @include breakpoint(large) {
      min-height: 350px;
      background-size: cover !important;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) - 0.5;
        }
        h2 {
          font-size: f-header-size(medium, h2) - .5;
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 350px;
      .hero-caption {
        padding-top: 4em;
        // h1 {
        //    font-size: f-header-size(medium, h1) + 1.25;
        // }
        // h2 {
        //    font-size: f-header-size(medium, h2) + 1;
        // }
      }
    }
  }
  //.stak:nth-child(even) {
  //  background-color: $light-gray;
  //}
}
// DEFAULT
.default {
  h2 {
    display: initial;
  }
  .hero {
    min-height: 200px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    //background: $green; /* Old browsers */
    //background: -moz-linear-gradient(-45deg, $green 0%, $gold 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(-45deg, $green 0%, $gold 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(135deg, $green 0%, $gold 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$green', endColorstr='$gold', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    &:before {
      @include overlay-gradient;
    }
    align-content: center;
    display: flex;
    .hero-caption {
      text-align: center;
      flex: 1 1 0px;
      margin: auto;
      z-index: 1;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        text-transform: uppercase;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      background-size: cover !important;
      background-position: center bottom;
    }
    @include breakpoint(large) {
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) - 0.5;
        }
        h2 {
          font-size: f-header-size(medium, h2) - .5;
        }
      }
    }
  }
  .article-content {
    @include stak;
  }
}
// RESOURCES
.resource-posts {
  .media {
    margin-bottom: 1em;
    padding-top: 1px;
    border-bottom: 1px $light-gray solid;
    .authors, .date, .source {
      font-size: .75em;
    }
    h4 {
      line-height: 1.25em;
      letter-spacing: .25px;
      font-size: 1rem;
    }
    @include breakpoint(medium) {
      h4 {
        line-height: 1.25em;
        font-size: f-header-size(medium, h4) -1;
      }
    }
  }
}
// NEWS
.news-collection {
  a {
    line-height: 1.25em;
    border-bottom: 1px solid $light-gray;
    .date {
      color: $dark-gray;
      display: block;
    }
    .source {
      color: $body-font-color;
      padding-right: .5em;
      font-weight: bold;
    }
    &:hover {
      background-color: $light-gray;
    }
  }
}
// PUBLICATIONS
.publication-list {
  @include no-bullet;
  li {
    border-bottom: 1px solid $light-gray;
    line-height: 1.25em;
    //a {
    //  padding: 0;
    //}
    p {
      margin-bottom: 0;
      display: inline-block;
    }
    .authors {
      color: $body-font-color;
      margin-top: 5px;
      color: $dark-gray;;
      display: inline-block;
      font-size: .85em;
    }
    .citation {
      color: $body-font-color;
      color: $dark-gray;;
      display: block;
      font-size: .85em;
    }
    .date {
      color: $dark-gray;
      display: inline-block;
      font-size: .85em;
    }
    .title {
      display: inline-block;
    }
  }
  li:hover {
    background-color: $light-gray;
  }
}
#offCanvasRightAside {
  h4 {
    border-bottom: 5px solid #ddac40;
    padding: 15px 0;
  }
  .menu a, .menu .button {
    //padding: 0.7rem 0rem;
  }
  .category-list a {
    padding: 0.7rem 1rem;
    display: inline;
    font-size: .8em;
  }
}
nav.article-category-list, .accordion-menu, nav.publication-years-list {
  margin: 15px 0;
  h4 {
    border-bottom: 5px solid #ddac40;
    padding: 15px 0;
  }
}
nav.publication-years-list {
  margin: 15px 0;
  h4 {
    border-bottom: 5px solid #ddac40;
    padding: 15px 0;
  }
  a {
    padding: 0.7rem .5rem .7rem 0;
    display: inline;
    font-size: .8em;
  }
}
// FAQs
.page-faqs {
  .accordion-stak {
    padding: 0 0 60px 0;
  }
}
//LOCATIONS
.alt-rows {
  .row {
    padding: 1em 0;
  }
  .row:nth-child(even) {
    background-color: $light-gray;
  }
}
.featured-brands {
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  img {
    //position: relative;
    //top: 50%;
    //transform: translateY(-50%);
    justify-self: center;
  }
}
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  opacity: 0;
}
.fadeout {
  opacity: 1;
}
.fadein-animation {
  animation-name: fadein-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($primary-color, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($primary-color, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary-color, 0);
  }
}
@keyframes fadein-animation {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadeout-animation {
  animation-name: fadeout-animation;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes fadeout-animation {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}
//Category Page Styles
//////////////////////////////////////////////////////////////////////////
.catalog-controls {
  margin-bottom: 1em;
  margin-top: 1em;
}
.tax-product_cat {
  article {
    border-bottom: 1px solid;
    padding: 25px 0;
  }
}
.cat-description {
  padding: 2em 0 1em 0;
  text-align: center;
}
// WOOCOMMERCE OVERRIDE STYLES
.product-page {
  margin-top: 2em;
  .tease-publications {
    margin-bottom: .5em;
    border-bottom: 1px solid $light-gray;
    a {
      font-weight: bold;
    }
    p {
      margin-bottom: 0;
    }
    span.citation, .authors {
      font-size: .8em;
    }
    .authors {
      margin-bottom: .5em;
    }
  }
}
.product-docs {
  margin-bottom: 1em;
  text-align: right;
  .ico {
    padding-right: .5em;
  }
}
.accordion-stak-title {
  margin: 1em auto;
}
.accordion-item {
  .accordion-title {
    &:before {
      font-size: 1.15em;
      font-weight: 800;
      margin-top: -0.75rem;
    }
    h4 {
      margin-bottom: 0 !important;
    }
  }
  &.is-active {
    .accordion-title {
      background-color: $secondary-color;
    }
  }
  .accordion-content {
    ul {
      li {
        margin-bottom: 1em;
      }
    }
  }
}
.sequence {
  display: inline;
  margin-left: .5em;
  .red {
    color: $red;
    font-weight: bold;
  }
  .yellow {
    color: $secondary-color;
    font-weight: bold;
  }
  .blue {
    color: $primary-color;
    font-weight: bold;
  }
}
.product-grid-view-all {
  font-size: .8em;
  margin-bottom: 1em;
  a {
    font-weight: bold;
  }
}
@mixin product-card-title {
  font-family: $header-font-family;
  padding: .5em 0;
  margin: 0;
  //font-size: 1em;
  line-height: 1.5em !important;
}
.product_title {
  font-family: $header-font-family;
  font-weight: 700;
  font-size: f-header-size(small, h1) - 1;
  text-shadow: none;
  @include breakpoint(medium) {
    font-size: f-header-size(small, h1);
  }
}
@media (min-width: 768px) {
  .woocommerce-checkout {
    #customer_details {
      width: 60%;
      float: left;
    }
    #order_review_heading {
      width: 30%;
      float: left;
    }
    #order_review {
      width: 40%;
      float: left;
      padding-right: 50px;
    }
    .woocommerce table.shop_table td {
      width: 50%;
    }
    .woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1,
    .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
      width: 100%;
    }
  }
}
.woocommerce {
  //Breadcrumbs
  .woocommerce-breadcrumb {
    margin: 1rem 0 1em !important;
    font-size: .8em;
    a {
      color: $green !important;
    }
  }
  //My Account Page
  .woocommerce-MyAccount-navigation {
    border-top: 5px solid $secondary-color;
    padding-top: 10px;
    li {
      list-style: none;
      padding: 6px;
      border-bottom: 1px solid #ebebeb;
      &.is-active {
        font-weight: bold;
        &:before {
          content: "»";
        }
      }
    }
  }
  .woocommerce-tabs {
    h2, h3, h4, h5 {
      margin-top: 40px;
    }
  }
  .tabs {
    border: none;
    border-bottom: 1px solid $light-gray;
  }
  .tabs-title {
    background-color: lighten($light-gray, 2%);
    border-right: 1px solid $light-gray;
    border-top: 1px solid $light-gray;
    border-left: 1px solid lighten($light-gray, 20%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: clip;
    font-weight: bold;
  }
  //Checkout page
  #customer_details {
    .col-1, .col-2 {
      width: 100% !important;
      float: left;
      @include breakpoint(medium) {
        padding: 15px 30px;
      }
    }
    margin-bottom: 25px;
  }
  .woocommerce-checkout #payment {
    border-radius: 0 !important;
  }
  .shop_table {
    border: none !important;
    .tbody {
      border: none !important;
    }
  }
  .woocommerce-billing-fields {
    //padding: 15px;
    margin-top: 10px;
  }
  .woocommerce-account-fields {
    padding: 0 15px;
    background: $primary-color;
    span {
      color: #fff;
    }
  }
  // SINGLE PRODUCT PAGE
  .product_title.entry-title {
    @include product-card-title;
  }
  .product_meta {
    padding: 1rem 0;
  }
  .quantity {
    a.minus {
      border: solid 2px black;
      border-right: 0;
    }
    a.plus {
      border: solid 2px black;
      border-left: 0;
    }
    input.qty {
      border: solid 2px black;
      min-height: 38px;
      margin-bottom: 20px;
      float: left;
    }
    //ORDER PAGE
    table.shop_table {
      border-radius: 0px !important;
    }
    #order_review_heading {
      margin-top: 60px;
    }
    form.checkout.woocommerce-checkout {
      margin: 30px 0;
    }
  }
  //Cart
  .coupon {
    .input-text {
      width: 170px !important;
    }
    .button {
      background: $green;
      color: $white;
    }
    .button[submit] {
      background: $green;
      color: $white;
    }
  }
}
thead {
  background: $green;
  color: $white;
}
.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
  background-color: $green !important;
  border-radius: 0;
  padding: 0.72em 1em;
}
.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover {
  background-color: $green !important;
}
.cart-menu {
  position: relative;
  //position: fixed;
  //z-index: 1000;
  //bottom: 1rem;
  //right: 1rem;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.cart-menu > .button {
  //padding: 0.5rem 1rem;
  //background-color: #333;
  //color: white;
  //border-radius: 4px;
}
.cart-mini-contents {
  position: relative;
  margin-right: 20px;
  &:after {
    content: "";
    display: inline-block; /* Ensures the pseudo-element behaves like an image */
    width: 1.5em; /* Width of the icon, adjust as needed */
    height: 1.5em; /* Height of the icon, adjust as needed */
    background-image: url('../images/grocery-store.png'); /* Path to your icon */
    background-size: contain; /* Makes sure the icon fits within the element's dimensions */
    background-repeat: no-repeat; /* Prevents the icon from repeating */
    margin-right: .25em;
  }
  span {
    font-size: .9em;
  }
  .amount {
    color: $gray;
  }
  .count {
    position: absolute;
    top: -14px;
    right: -20px;
    background: $light-green;
    color: $white;
    font-weight: bolder;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    text-align: center;
  }
  &:hover {
    &:after {
      color: $secondary-color;
    }
  }
  &.empty {
    span {
      display: none;
    }
    &:after {
      @include flaticon;
      color: $gray;
    }
  }
}
.cart-dialog {
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.25s ease-in-out;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  width: 320px;
  max-height: 480px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding: 1em;
}
.cart-dialog.cart-dialog--active {
  visibility: visible;
  opacity: 1;
  transform: none;
}
.cart-dialog .widgettitle {
  display: none;
}
.cart-dialog .widget {
  margin-bottom: 0;
}
.cart-dialog .widget img {
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 4px;
}
.widget_shopping_cart_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
ul.product_list_widget {
  grid-column: span 2;
  margin: 0;
  padding: 0.5rem 1rem;
  list-style-type: none;
}
ul.product_list_widget img {
  float: right;
}
ul.product_list_widget a {
  display: block;
}
ul.product_list_widget li.mini_cart_item {
  position: relative;
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 1.25;
}
ul.product_list_widget li.mini_cart_item::before,
ul.product_list_widget li.mini_cart_item::after {
  content: " ";
  display: table;
}
ul.product_list_widget li.mini_cart_item::after {
  clear: both;
}
ul.product_list_widget a.remove_from_cart_button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: red;
  color: white;
  font-weight: 700;
  transform: translateX(50%) translateY(-4px);
  border-radius: 50%;
}
ul.product_list_widget p {
  margin: 0.5rem 0;
}
ul.product_list_widget span.quantity {
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
}
.woocommerce-mini-cart__total {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0.5rem 0.25rem;
  padding-left: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  line-height: 1;
}
.woocommerce-mini-cart__total strong {
  display: block;
  font-size: 14px;
}
p.woocommerce-mini-cart__buttons {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0;
  padding: 0.5rem 0.25rem;
  padding-right: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  line-height: 1;
}
p.woocommerce-mini-cart__buttons .button {
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0;
  color: white;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 4px;
}
p.woocommerce-mini-cart__buttons .button:not(.checkout) {
  display: none;
}
p.woocommerce-mini-cart__buttons .button.checkout {
  background-color: #0073aa;
}
.alt-stak {
  li.product {
    border: none !important;
  }
}
.woocommerce, .woocommerce-page {
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
    //font-size: f-header-size(small, h3);
  }
  ul.products {
    @include breakpoint(medium) {
      @include flex-grid-row($columns: 12);
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      margin: 0 auto !important;
      &.columns-4 {
        li.product {
          @include flex-grid-column($columns: 3, $gutters: 0);
          flex: 0 0 22%;
          max-width: 22%;
        }
      }
    }
  }
  li.product {
    text-align: center;
    position: relative;
    //float: none;
    //margin: 0 auto 1em auto !important;
    // width: initial !important;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
    padding-bottom: 2rem !important;
    padding-top: 1rem !important;
    //border-bottom: 1px solid $light-gray;
    border: 2px solid $light-gray;
    //@include flex-grid-column($columns: 6);
    //@include breakpoint(medium) {
    //  @include flex-grid-column($columns: 3, $gutters: 0);
    //}
    h2.woocommerce-loop-product__title {
      text-shadow: none;
      min-height: 4em;
      display: block;
      width: 90%;
      margin: 0 auto !important;
      padding-bottom: 0 !important;
      @include product-card-title;
      font-family: $header-font-family;
      font-weight: 700;
      font-size: f-header-size(small, h1) - 1;
      a {
        color: $body-font-color;
        transition: all .25s;
        &:hover {
          color: $primary-color;
        }
      }
    }
    @include breakpoint(medium) {
      h2.woocommerce-loop-product__title {
        font-size: f-header-size(small, h1);
        min-height: 1.25em !important;
      }
    }
    .woocommerce-loop-product__link {
      margin-bottom: auto !important;
      flex-grow: 1;
    }
    .media {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      flex-direction: column; /* new */
    }
    .media-content {
      width: 100%;
    }
    .button-product-link {
      margin-top: 1em;
      width: 100%;
      align-self: flex-end;
    }
    .button {
      margin-top: auto;
      font-size: 80%;
      line-height: 1;
      cursor: pointer;
      position: relative;
      text-decoration: none;
      overflow: visible;
      font-weight: 700;
      border: 1px solid $green;
      border-radius: 4px;
      left: auto;
      color: $white;
      //border: 0;
      // display: inline-block;
      background-image: none;
      box-shadow: none;
      text-shadow: none;
      margin-top: auto !important;
      align-self: center;
      padding: 0.85em 1.2em;
      background-color: $green !important;
      transition: all .25s;
      &:hover {
        box-shadow: 0px 20px 20px -5px rgba($black, 0.4);
        border-color: $light-green;
        //color: $light-green;
        background-color: $button-background-hover !important;
        //  animation: pulse 2s infinite;
      }
      @include breakpoint(medium) {
        font-size: 100%;
      }
    }
    .price, .card-size {
      color: $primary-color !important;
      display: block;
      font-weight: 600 !important;
      margin-bottom: .5em;
      font-size: 1em;
      display: inline !important;
      width: 50%;
      text-align: center;
      > del {
        opacity: .5;
      }
      > ins {
        font-weight: 700;
      }
    }
    .card-size {
      padding-left: .5em;
      color: $gray !important;
    }
  }
}
.description-always-open {
  display: block !important;
}
.featured-grid {
  background-color: lighten($light-gray, 3.5%);
}
.woocommerce ul.products li.product .price {
  color: #77a464;
  display: block;
  font-weight: 400;
  margin-bottom: .5em;
  font-size: .857em;
}
.woocommerce .product img {
  //max-height: 300px;
}
.woocommerce-loop-category__title, .woocommerce-loop-product__title {
  @include product-card-title;
}
.filter-button {
  margin: 1em auto;
  text-align: center;
  button {
    @include button;
  }
}
.filter-menu {
  background-color: $white;
  form {
    max-width: 280px;
    padding: 1em 1em 0 1em;
    @include breakpoint(large) {
      padding: 0;
    }
  }
  .facet-label {
    border-bottom: 5px solid $black;
    padding: 15px 0 6px 0;
    margin-bottom: 1em;
  }
  .facet-search {
    margin-bottom: 2em;
    .facet-label {
      border-bottom: none;
      padding: 15px 0 0 0;
      margin-bottom: 0;
    }
  }
  .facetwp-facet {
    margin-bottom: 1em;
  }
  .facetwp-facet {
    font-size: .8em;
  }
  .facetwp-checkbox {
    font-weight: bold;
    padding-bottom: .5em;
    background-position: 0 3px;
  }
  .facetwp-depth {
    .facetwp-checkbox {
      font-weight: normal;
    }
  }
}
.product-pager-section {
  margin: 2em auto;
  padding-top: 2em;
  text-align: center;
  border-top: 1px solid $light-gray;
  .facetwp-facet {
    margin-bottom: 1em;
  }
  h4 {
    display: none;
  }
  a.facetwp-page {
    background: $light-gray;
    color: $primary-color;
    padding: 5px 12px;
    &:hover {
      background: $secondary-color;
      color: $white;
    }
    &.active {
      background: $primary-color;
      color: $white;
    }
  }
}
.orderby, .facetwp-sort-select, .facetwp-per-page-select {
  border: none;
  color: $gray;
  background-color: $light-gray;
  margin: 0 0 1em 0;
  padding-left: 1em;
  box-shadow: inset 0 1px 2px rgba($gray, .5);
  width: 300px;
  &::placeholder {
    color: $medium-gray;
    font-size: .9em;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
.facetwp-sort {
  text-align: center;
  @include breakpoint(medium) {
    text-align: right;
  }
}
.facetwp-search {
  border: none;
  box-shadow: none;
  border-bottom: 2px solid $gray;
}
.product-card-list .woocommerce .up-sells li.product {
  /* flex: 0 0 25%; */
  max-width: 100% !important;
  flex: 0 0 100%;
}
.main-section.search-page {
  h1 {
    margin: 1em auto .5em auto;
    font-size: 1.75rem;
  }
  .result {
    border-top: 1px solid $light-gray;
    margin: 1em 0 .5em 0;
    padding-top: 2.5em;
  }
  .post-type {
    padding: .25em 0.75em;
    margin-bottom: .5em;
    background-color: $green;
    border-top: 2px solid #DDAC40;
    color: $white;
    font-size: .85em;
    display: inline-block;
  }
  a[target=_blank]:after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
  }
}
//ADDITIONAL BUTTON STYLES
.button {
  padding: 0.85em 1.2em;
}
.button-group {
  .button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
//CONTACT PAGE
.contact-form {
  .secondary {
    background: #5e6681;
    padding: 40px;
    color: $white;
    border-top: 5px solid $primary-color;
  }
  label {
    color: $white;
  }
}
//Facets
h4.facet-label {
  border-bottom: 5px solid $primary-color;
  padding: 15px 0;
}
.facetwp-checkbox {
  margin-bottom: 10px;
}
//Labels
.label {
  background: none;
}
.thumbnail {
  box-shadow: none;
}
// WordPress alignment classes
.aligncenter {
  display: flex;
  flex: 1;
  margin: auto;
}
.alignright {
  float: right;
  margin-left: auto;
}
.alignleft {
  float: left;
  margin-right: auto;
}
// Contact form
input[type="submit"] {
  @include button;
}
div.images {
  margin-bottom: 2em;
  img {
    display: block;
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  div.thumbnails {
    padding-top: 1em;
  }
  &.woocommerce-product-gallery {
    position: relative;
  }
  .woocommerce-product-gallery__wrapper {
    transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
    margin: 0;
    padding: 0;
  }
  .woocommerce-product-gallery__wrapper .zoomImg {
    background-color: #fff;
    opacity: 0;
  }
  .woocommerce-product-gallery__image--placeholder {
    border: 1px solid #f2f2f2;
  }
  .woocommerce-product-gallery__image:nth-child(n+2) {
    width: 25%;
    display: inline-block;
  }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    font-size: 2em;
    z-index: 9;
    width: 36px;
    height: 36px;
    background: #fff;
    text-indent: -9999px;
    border-radius: 100%;
    box-sizing: content-box;
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border: 2px solid #000;
      border-radius: 100%;
      position: absolute;
      top: 9px;
      left: 9px;
      box-sizing: content-box;
    }
    &::after {
      content: "";
      display: block;
      width: 2px;
      height: 8px;
      background: #000;
      border-radius: 6px;
      position: absolute;
      top: 19px;
      left: 22px;
      transform: rotate(-45deg);
      box-sizing: content-box;
    }
  }
  .flex-control-thumbs {
    overflow: hidden;
    zoom: 1;
    margin: 0;
    padding: 0;
    li {
      width: 25%;
      float: left;
      margin: 0;
      list-style: none;
      img {
        cursor: pointer;
        opacity: 0.5;
        margin: 0;
        &.flex-active,
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.woocommerce-product-gallery--columns-3 {
  .flex-control-thumbs li:nth-child(3n+1) {
    clear: left;
  }
}
.woocommerce-product-gallery--columns-4 {
  .flex-control-thumbs li:nth-child(4n+1) {
    clear: left;
  }
}
.woocommerce-product-gallery--columns-5 {
  .flex-control-thumbs li:nth-child(5n+1) {
    clear: left;
  }
}
.woocommerce .woocommerce-ordering {
  margin: 0 !important;
}
//SIMPLE JOB BOARD
.job-info {
  h4 {
    .job-title {
      font-weight: bolder;
    }
  }
}
.single-jobpost {
  .stak:first-of-type {
    background: linear-gradient(180deg, rgba($light-gray, .5) 0%, $white 100%);
  }
}
.sjb-page .list-data .v1 {
  background-color: transparent !important;
}
.sjb-page {
  background-color: transparent !important;
  .sjb-detail {
    margin-top: 0 !important;
    .list-data .v1 .job-features .table td:first-child {
      padding-left: 15px !important;
    }
    table {
      tr:first-child {
        font-weight: bold;
      }
    }
  }
}