/*
Flaticon icon font: Flaticon
Creation date: 11/09/2020 19:05
*/

@font-face {
   font-family: "Flaticon";
   src: url("../fonts/Flaticon.eot");
   src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
   url("../fonts/Flaticon.woff2") format("woff2"),
   url("../fonts/Flaticon.woff") format("woff"),
   url("../fonts/Flaticon.ttf") format("truetype"),
   url("../fonts/Flaticon.svg#Flaticon") format("svg");
   font-weight: normal;
   font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
   @font-face {
      font-family: "Flaticon";
      src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
   }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
   display: inline-block;
   font-family: "Flaticon";
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   line-height: 1;
   text-decoration: inherit;
   text-rendering: optimizeLegibility;
   text-transform: none;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   font-smoothing: antialiased;
}
.fi:before{
   display: inline-block;
   font-family: "Flaticon";
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   line-height: 1;
   text-decoration: inherit;
   text-rendering: optimizeLegibility;
   text-transform: none;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   font-smoothing: antialiased;
}

.flaticon-instagram:before { content: "\f100"; }
.flaticon-twitter:before { content: "\f101"; }
.flaticon-facebook:before { content: "\f102"; }
.flaticon-linkedin:before { content: "\f103"; }
.flaticon-pinterest:before { content: "\f104"; }
.flaticon-youtube:before { content: "\f105"; }
.flaticon-vimeo:before { content: "\f106"; }
.flaticon-dry-clean:before { content: "\f107"; }
.flaticon-next:before { content: "\f108"; }
.flaticon-down:before { content: "\f109"; }
.flaticon-chevron:before { content: "\f10a"; }
.flaticon-down-1:before { content: "\f10b"; }
.flaticon-arrow:before { content: "\f10c"; }
.flaticon-cancel:before { content: "\f10d"; }
.flaticon-arrow-1:before { content: "\f10e"; }
.flaticon-arrow-2:before { content: "\f10f"; }
.flaticon-arrow-3:before { content: "\f110"; }
.flaticon-page:before { content: "\f111"; }
.flaticon-pin:before { content: "\f112"; }
.flaticon-earth:before { content: "\f113"; }
.flaticon-mail:before { content: "\f114"; }
.flaticon-mail-1:before { content: "\f115"; }
.flaticon-phone:before { content: "\f116"; }
.flaticon-phone-1:before { content: "\f117"; }
.flaticon-play-button:before { content: "\f118"; }
.flaticon-video-camera:before { content: "\f119"; }
.flaticon-user:before { content: "\f11a"; }
.flaticon-bag:before { content: "\f11b"; }
.flaticon-bag-1:before { content: "\f11c"; }
.flaticon-chat:before { content: "\f11d"; }
.flaticon-conversation:before { content: "\f11e"; }

$font-Flaticon-instagram: "\f100";
$font-Flaticon-twitter: "\f101";
$font-Flaticon-facebook: "\f102";
$font-Flaticon-linkedin: "\f103";
$font-Flaticon-pinterest: "\f104";
$font-Flaticon-youtube: "\f105";
$font-Flaticon-vimeo: "\f106";
$font-Flaticon-dry-clean: "\f107";
$font-Flaticon-next: "\f108";
$font-Flaticon-down: "\f109";
$font-Flaticon-chevron: "\f10a";
$font-Flaticon-down-1: "\f10b";
$font-Flaticon-arrow: "\f10c";
$font-Flaticon-cancel: "\f10d";
$font-Flaticon-arrow-1: "\f10e";
$font-Flaticon-arrow-2: "\f10f";
$font-Flaticon-arrow-3: "\f110";
$font-Flaticon-page: "\f111";
$font-Flaticon-pin: "\f112";
$font-Flaticon-earth: "\f113";
$font-Flaticon-mail: "\f114";
$font-Flaticon-mail-1: "\f115";
$font-Flaticon-phone: "\f116";
$font-Flaticon-phone-1: "\f117";
$font-Flaticon-play-button: "\f118";
$font-Flaticon-video-camera: "\f119";
$font-Flaticon-user: "\f11a";
$font-Flaticon-bag: "\f11b";
$font-Flaticon-bag-1: "\f11c";
$font-Flaticon-chat: "\f11d";
$font-Flaticon-conversation: "\f11e";
